body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F5F5F5 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.multilevel-dropdown-menu {
  max-height: 200px;
  /* Set your desired maximum height here */
  overflow-y: auto;
  /* Add a scrollbar if the content overflows */
}

.multilevel-dropdown-submenu {
  max-height: 200px;
  /* Set your desired maximum height for submenu items */
  overflow-y: auto;
}

@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-icons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-gantt/styles/material.css';
@import "../node_modules/@syncfusion/ej2-react-grids/styles/material.css";
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-layouts/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';

@import '../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css';
@import "../node_modules/@syncfusion/ej2-react-kanban/styles/material.css";
@import '../node_modules/@syncfusion/ej2-treegrid/styles/material.css';

/* .e-filtered {
  color: red !important;
} */