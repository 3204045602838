.e-control {
    border: 0;
}

/* .e-file-select-wrap,
.e-upload,
.e-control-wrapper,
.e-lib,
.e-keyboard {
    display: none;
} */

.e-file-select-wrap,
.e-control-wrapper,
.e-control .e-file-drop {
    /* display: none; */
}