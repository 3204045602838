/* .e-gantt-child-progressbar-inner-div {
    background: #FFC600 !important;
}

.e-gantt-child-taskbar-inner-div {
    background: #f5e342 !important;
    border: #fccf03 1px !important;
} */

.e-dlg-header-content,
.e-tab-header {
    background: #34425a !important;
    color: black !important;
}