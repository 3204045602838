* {
    box-sizing: border-box;
}

body {
    font-size: 14px;
}

.v6_3 {
    width: 100%;
    height: 1194px;
    background: rgba(245, 245, 245, 1);
    opacity: 1;
    position: relative;
    top: 0px;
    left: 0px;
    overflow: hidden;
}

.v6_5 {
    width: 256px;
    height: 392px;
    background: rgba(255, 255, 255, 1);
    opacity: 1;
    position: absolute;
    top: 377px;
    left: 673px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.03999999910593033);
    overflow: hidden;
}

.v6_40 {
    width: 256px;
    height: 392px;
    background: rgba(255, 255, 255, 1);
    opacity: 1;
    position: absolute;
    top: 377px;
    left: 389px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.03999999910593033);
    overflow: hidden;
}

.v7_79 {
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 1);
    opacity: 1;
    position: absolute;
    top: 697px;
    left: 431px;
    overflow: hidden;
}

.v7_80 {
    width: 13px;
    height: 17px;
    background: rgba(119, 119, 119, 1);
    opacity: 1;
    position: absolute;
    top: 1px;
    left: 3px;
}

.v6_41 {
    width: 256px;
    height: 392px;
    background: rgba(255, 255, 255, 1);
    opacity: 1;
    position: absolute;
    top: 377px;
    left: 105px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.03999999910593033);
    overflow: hidden;
}

.v6_6 {
    width: 43px;
    color: rgba(119, 119, 119, 1);
    position: absolute;
    top: 649px;
    left: 136px;
    font-family: Roboto;
    font-weight: SemiBold;
    font-size: 10px;
    opacity: 1;
    text-align: left;
}

.v6_7 {
    width: 79px;
    color: rgba(119, 119, 119, 1);
    position: absolute;
    top: 649px;
    left: 421px;
    font-family: Roboto;
    font-weight: SemiBold;
    font-size: 10px;
    opacity: 1;
    text-align: left;
}

.v6_8 {
    width: 62px;
    color: rgba(119, 119, 119, 1);
    position: absolute;
    top: 649px;
    left: 706px;
    font-family: Roboto;
    font-weight: SemiBold;
    font-size: 10px;
    opacity: 1;
    text-align: left;
}

.v6_9 {
    width: 17px;
    color: rgba(119, 119, 119, 1);
    position: absolute;
    top: 649px;
    left: 309px;
    font-family: Roboto;
    font-weight: SemiBold;
    font-size: 10px;
    opacity: 1;
    text-align: left;
}

.v6_10 {
    width: 17px;
    color: rgba(119, 119, 119, 1);
    position: absolute;
    top: 649px;
    left: 594px;
    font-family: Roboto;
    font-weight: SemiBold;
    font-size: 10px;
    opacity: 1;
    text-align: left;
}

.v6_11 {
    width: 17px;
    color: rgba(119, 119, 119, 1);
    position: absolute;
    top: 649px;
    left: 879px;
    font-family: Roboto;
    font-weight: SemiBold;
    font-size: 10px;
    opacity: 1;
    text-align: left;
}

.v6_19 {
    width: 60px;
    color: rgba(119, 119, 119, 1);
    position: absolute;
    top: 700px;
    left: 486px;
    font-family: Roboto;
    font-weight: SemiBold;
    font-size: 12px;
    opacity: 1;
    text-align: center;
}

.v6_22 {
    width: 190px;
    height: 35px;
    /* background: url("../images/v6_22.png"); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 1;
    position: absolute;
    top: 690px;
    left: 421px;
    border: 1px solid rgba(217, 217, 217, 1);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    overflow: hidden;
}

.v6_24 {
    width: 200px;
    color: rgba(64, 64, 64, 1);
    position: absolute;
    top: 415px;
    left: 131px;
    font-family: Roboto;
    font-weight: SemiBold;
    font-size: 20px;
    opacity: 1;
    text-align: center;
}

.v7_119 {
    width: 363px;
    color: rgba(64, 64, 64, 1);
    position: absolute;
    top: 51px;
    left: 53px;
    font-family: Roboto;
    font-weight: SemiBold;
    font-size: 20px;
    opacity: 1;
    text-align: left;
}

.v6_25 {
    width: 200px;
    color: rgba(64, 64, 64, 1);
    position: absolute;
    top: 415px;
    left: 416px;
    font-family: Roboto;
    font-weight: SemiBold;
    font-size: 20px;
    opacity: 1;
    text-align: center;
}

.v6_26 {
    width: 109px;
    height: 84px;
    background: rgba(255, 255, 255, 1);
    opacity: 1;
    position: absolute;
    top: 525px;
    left: 747px;
    overflow: hidden;
}

.v6_27 {
    width: 114px;
    height: 87px;
    /* background: url("../images/v6_27.png"); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 1;
    position: absolute;
    top: 2px;
    left: 2px;
    overflow: hidden;
}

.v6_28 {
    width: 100px;
    height: 71px;
    /* background: url("../images/v6_28.png"); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 1;
    position: absolute;
    top: 17px;
    left: 0px;
    transform: rotate(-10deg);
    overflow: hidden;
}

.v6_29 {
    width: 100px;
    height: 70px;
    background: rgba(64, 64, 64, 1);
    opacity: 1;
    position: relative;
    top: 0px;
    left: 0px;
    transform: rotate(-9deg);
}

.v6_30 {
    width: 200px;
    color: rgba(64, 64, 64, 1);
    position: absolute;
    top: 415px;
    left: 701px;
    font-family: Roboto;
    font-weight: SemiBold;
    font-size: 20px;
    opacity: 1;
    text-align: center;
}

.v6_31 {
    width: 190px;
    height: 4px;
    background: rgba(217, 217, 217, 1);
    opacity: 1;
    position: absolute;
    top: 669px;
    left: 136px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    overflow: hidden;
}

.v6_32 {
    width: 190px;
    height: 4px;
    background: rgba(217, 217, 217, 1);
    opacity: 1;
    position: absolute;
    top: 669px;
    left: 421px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    overflow: hidden;
}

.v6_33 {
    width: 190px;
    height: 4px;
    background: rgba(217, 217, 217, 1);
    opacity: 1;
    position: absolute;
    top: 669px;
    left: 706px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    overflow: hidden;
}

.v6_34 {
    width: 120px;
    height: 4px;
    background: rgba(255, 198, 0, 1);
    opacity: 1;
    position: absolute;
    top: 669px;
    left: 136px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    overflow: hidden;
}

.v6_35 {
    width: 66px;
    height: 4px;
    background: rgba(255, 198, 0, 1);
    opacity: 1;
    position: absolute;
    top: 669px;
    left: 421px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    overflow: hidden;
}

.v6_36 {
    width: 190px;
    height: 4px;
    background: rgba(255, 198, 0, 1);
    opacity: 1;
    position: absolute;
    top: 669px;
    left: 706px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    overflow: hidden;
}

.v6_37 {
    width: 87px;
    height: 90px;
    background: rgba(255, 255, 255, 1);
    opacity: 1;
    position: absolute;
    top: 519px;
    left: 187px;
    overflow: hidden;
}

.v6_38 {
    width: 87px;
    height: 90px;
    /* background: url("../images/v6_38.png"); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 1;
    position: relative;
    top: 0px;
    left: 0px;
    overflow: hidden;
}

.v6_39 {
    width: 87px;
    height: 90px;
    background: rgba(64, 64, 64, 1);
    opacity: 1;
    position: relative;
    top: 0px;
    left: 0px;
}

.v7_45 {
    width: 60px;
    height: 134px;
    /* background: url("../images/v7_45.png"); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 1;
    position: absolute;
    top: 500px;
    left: 487px;
    overflow: hidden;
}

.v9_805 {
    width: 190px;
    height: 35px;
    /* background: url("../images/v9_805.png"); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 1;
    position: absolute;
    top: 690px;
    left: 136px;
    overflow: hidden;
}

.v6_18 {
    width: 81px;
    color: rgba(64, 64, 64, 1);
    position: absolute;
    top: 10px;
    left: 55px;
    font-family: Roboto;
    font-weight: SemiBold;
    font-size: 12px;
    opacity: 1;
    text-align: center;
}

.v6_21 {
    width: 190px;
    height: 35px;
    /* background: url("../images/v6_21.png"); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 1;
    position: absolute;
    top: 0px;
    left: 0px;
    border: 2px solid rgba(255, 198, 0, 1);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    overflow: hidden;
}

.v7_99 {
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 1);
    opacity: 1;
    position: absolute;
    top: 8px;
    left: 10px;
    overflow: hidden;
}

.v7_100 {
    width: 15px;
    height: 16px;
    background: rgba(64, 64, 64, 1);
    opacity: 1;
    position: absolute;
    top: 1px;
    left: 2px;
}

.v7_101 {
    width: 24px;
    height: 24px;
    background: rgba(255, 198, 0, 1);
    opacity: 1;
    position: absolute;
    top: 818px;
    left: 105px;
    border-radius: 50%;
}

.v7_107 {
    width: 24px;
    height: 24px;
    background: rgba(255, 198, 0, 1);
    opacity: 1;
    position: absolute;
    top: 818px;
    left: 389px;
    border-radius: 50%;
}

.v7_111 {
    width: 24px;
    height: 24px;
    background: rgba(255, 198, 0, 1);
    opacity: 1;
    position: absolute;
    top: 818px;
    left: 673px;
    border-radius: 50%;
}

.v7_102 {
    width: 16px;
    color: rgba(64, 64, 64, 1);
    position: absolute;
    top: 823px;
    left: 109px;
    font-family: Roboto;
    font-weight: ExtraBold;
    font-size: 16px;
    opacity: 1;
    text-align: center;
}

.v7_108 {
    width: 16px;
    color: rgba(64, 64, 64, 1);
    position: absolute;
    top: 823px;
    left: 393px;
    font-family: Roboto;
    font-weight: ExtraBold;
    font-size: 16px;
    opacity: 1;
    text-align: center;
}

.v7_112 {
    width: 16px;
    color: rgba(64, 64, 64, 1);
    position: absolute;
    top: 823px;
    left: 677px;
    font-family: Roboto;
    font-weight: ExtraBold;
    font-size: 16px;
    opacity: 1;
    text-align: center;
}

.v7_103 {
    width: 202px;
    color: rgba(64, 64, 64, 1);
    position: absolute;
    top: 818px;
    left: 137px;
    font-family: Roboto;
    font-weight: SemiBold;
    font-size: 14px;
    opacity: 1;
    text-align: left;
}

.v7_109 {
    width: 202px;
    color: rgba(64, 64, 64, 1);
    position: absolute;
    top: 818px;
    left: 421px;
    font-family: Roboto;
    font-weight: SemiBold;
    font-size: 14px;
    opacity: 1;
    text-align: left;
}

.v7_113 {
    width: 202px;
    color: rgba(64, 64, 64, 1);
    position: absolute;
    top: 818px;
    left: 705px;
    font-family: Roboto;
    font-weight: SemiBold;
    font-size: 14px;
    opacity: 1;
    text-align: left;
}

.v7_104 {
    width: 202px;
    /* color: url("../images/v7_104.png"); */
    position: absolute;
    top: 875px;
    left: 137px;
    font-size: 14px;
    opacity: 1;
    text-align: left;
}

.v7_132 {
    width: 379px;
    /* color: url("../images/v7_132.png"); */
    position: absolute;
    top: 1124px;
    left: 53px;
    font-size: 14px;
    opacity: 1;
    text-align: left;
}

.v7_110 {
    width: 202px;
    /* color: url("../images/v7_110.png"); */
    position: absolute;
    top: 875px;
    left: 421px;
    font-size: 14px;
    opacity: 1;
    text-align: left;
}

.v7_114 {
    width: 202px;
    /* color: url("../images/v7_114.png"); */
    position: absolute;
    top: 875px;
    left: 705px;
    font-size: 14px;
    opacity: 1;
    text-align: left;
}

.v7_115 {
    width: 16px;
    height: 16px;
    background: rgba(255, 198, 0, 1);
    opacity: 1;
    position: absolute;
    top: 648px;
    left: 186px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    overflow: hidden;
}

.v7_116 {
    width: 10px;
    color: rgba(64, 64, 64, 1);
    position: absolute;
    top: 648px;
    left: 188px;
    font-family: Roboto;
    font-weight: SemiBold;
    font-size: 8px;
    opacity: 1;
    text-align: right;
}

.v7_117 {
    width: 20px;
    height: 16px;
    background: rgba(255, 198, 0, 1);
    opacity: 1;
    position: absolute;
    top: 701px;
    left: 869px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    overflow: hidden;
}

.v9_806 {
    width: 190px;
    height: 35px;
    /* background: url("../images/v9_806.png"); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 1;
    position: absolute;
    top: 690px;
    left: 706px;
    overflow: hidden;
}

.v6_23 {
    width: 190px;
    height: 35px;
    background: rgba(255, 198, 0, 1);
    opacity: 1;
    position: absolute;
    top: 0px;
    left: 0px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    overflow: hidden;
}

.v7_62 {
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 1);
    opacity: 1;
    position: absolute;
    top: 7px;
    left: 10px;
    overflow: hidden;
}

.v7_63 {
    width: 16px;
    height: 15px;
    background: rgba(64, 64, 64, 1);
    opacity: 1;
    position: absolute;
    top: 2px;
    left: 1px;
}

.v6_20 {
    width: 79px;
    color: rgba(64, 64, 64, 1);
    position: absolute;
    top: 10px;
    left: 56px;
    font-family: Roboto;
    font-weight: SemiBold;
    font-size: 12px;
    opacity: 1;
    text-align: center;
}

.v7_118 {
    width: 14px;
    color: rgba(64, 64, 64, 1);
    position: absolute;
    top: 10px;
    left: 164px;
    font-family: Roboto;
    font-weight: SemiBold;
    font-size: 12px;
    opacity: 1;
    text-align: left;
}

.MuiSlider-thumb {

    display: none !important;
}






/* .MuiSlider-thumb:is(:hover, :enabled, .Mui-active) {
    display: none;
} */

.v7_120 {
    width: 404px;
    /* color: url("../images/v7_120.png"); */
    position: absolute;
    top: 87px;
    left: 53px;
    font-size: 14px;
    opacity: 1;
    text-align: left;
}

.v9_138 {
    width: 190px;
    height: 35px;
    /* background: url("../images/v9_138.png"); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 1;
    position: absolute;
    top: 191px;
    left: 54px;
    overflow: hidden;
}

.v7_121 {
    width: 190px;
    height: 35px;
    background: rgba(255, 198, 0, 1);
    opacity: 1;
    position: relative;
    top: 0px;
    left: 0px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    overflow: hidden;
}

.v7_124 {
    width: 81px;
    color: rgba(64, 64, 64, 1);
    position: absolute;
    top: 10px;
    left: 55px;
    font-family: Roboto;
    font-weight: SemiBold;
    font-size: 12px;
    opacity: 1;
    text-align: center;
}

.v7_126 {
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 1);
    opacity: 1;
    position: absolute;
    top: 7px;
    left: 10px;
    overflow: hidden;
}

.v7_127 {
    width: 15px;
    height: 16px;
    background: rgba(64, 64, 64, 1);
    opacity: 1;
    position: absolute;
    top: 1px;
    left: 3px;
}

.v7_128 {
    width: 113px;
    color: rgba(255, 198, 0, 1);
    position: absolute;
    top: 301px;
    left: 55px;
    font-family: Roboto;
    font-weight: SemiBold;
    font-size: 14px;
    opacity: 1;
    text-align: left;
}

.v7_129 {
    width: 126px;
    color: rgba(64, 64, 64, 1);
    position: absolute;
    top: 301px;
    left: 204px;
    font-family: Roboto;
    font-weight: SemiBold;
    font-size: 14px;
    opacity: 1;
    text-align: left;
}

.v7_130 {
    width: 150px;
    height: 2px;
    background: rgba(255, 198, 0, 1);
    opacity: 1;
    position: absolute;
    top: 321px;
    left: 38px;
    overflow: hidden;
}

.name {
    color: #fff;
}

.v9_137 {
    width: 324px;
    height: 216px;
    /* background: url("../images/v9_137.png"); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 1;
    position: absolute;
    top: 51px;
    left: 605px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.25);
    overflow: hidden;
}