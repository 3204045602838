.splitter-container {
    display: flex;
    width: 100%;
    height: 100%;
}

.splitter-pane {
    overflow: auto;
}

.splitter-divider {
    width: 5px;
    cursor: col-resize;
    background-color: #ffffff;
    z-index: 1;
}