.selectable {
    /* width: 200px; */
    /* height: 25px;
    margin: 2px;
    padding: 10px;
    border: 1px solid #ddd; */
    cursor: pointer;
}

.selected {
    background-color: #f5f5f5;
    /* color: #fff; */
}