#parent {

    display: grid;
}

#parent .hidden-child {
    visibility: hidden;
}

.imageThumb {
    margin: 8px;
    box-shadow: 3px 3px gray;
    border-radius: 10px;
    grid-area: 1 / 1;
}

.e-toolbar .e-tbar-btn-text,
.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text {
    display: none;
}

@media (min-width: 700px) {

    .e-toolbar .e-tbar-btn-text,
    .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text {
        display: inline;
    }
}



#parent:hover .hidden-child {


    visibility: visible;
    grid-area: 1 / 1;
    margin: '6px'
}

.expander {
    box-shadow: 3px 3px 3px lightgray;
    margin-top: 20px;

}

.react-horizontal-scrolling-menu--item:first-child {
    margin-left: 50px;
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}


.expander-header {
    background-color: white;
    border: 1px gray;
    box-shadow: 3px 3px 6px lightgray;
    margin-top: 20px;

}