.home-container {
    height: 100%;
    margin: auto;
    background-color: white;
}



.img-noover {
    page-break-before: auto;
    /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
    page-break-after: auto;
    /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
    page-break-inside: avoid !important;
    /* or 'auto' */
    position: relative;
    width: 300px;
    height: 300px;
    /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */

    /* or 'auto' */
    margin: 1px;

}



.one {
    left: 10px;
    position: relative;
    width: 32%;
    height: auto;

}

.two {
    left: 320px;
    position: relative;
    width: 32%;
    height: 200px;
}

.three {
    left: 640px;
    position: relative;
    width: 32%;
    height: 200px;
}

/* table,
tr {
    border-collapse: collapse;
    border: 1px solid;
    border-color: lightgray;
    width: 100%;
    margin-top: 24px;
} */

th {
    border-collapse: collapse;
    border: 1px solid;
    border-color: lightgray;
    padding: 6px;
}

td {
    border-collapse: collapse;
    border: 1px solid;
    border-color: lightgray;
    padding: 6px;
}




.obal {
    border: 1px solid;
    border-color: lightgray;
    padding: 24px;
    margin: 20px;
    border-radius: 20px;
    /* box-shadow: 4px 4px 4px gray; */
    display: block;
    /* page-break-inside: avoid !important; */
}

.obal-right {
    border: 1px solid;
    border-color: lightgray;
    padding: 24px;
    margin: 20px;
    border-radius: 20px;
    /* box-shadow: 4px 4px 4px gray; */
    display: block;
    float: right;
    /* page-break-inside: avoid !important; */
}