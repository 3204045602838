.text-field {
    width: 100%;
    height: 64px;
  }
  .Auth-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
  }
  .Auth-form-popup{
    width: 100vw;
    height: 100vh;
  }
  .e-toast-container .e-toast {
    background-color: navy;
  }
  .Auth-form {
    width: 420px;
    box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
    padding-top: 30px;
    padding-bottom: 20px;
    border-radius: 8px;
    background-color: white;
  }
  .logo-up {
    text-align: center;
    margin-bottom: 32px;
  }
  .Auth-form-content {
    padding-left: 12%;
    padding-right: 12%;
    overflow-x: auto;
  }
  
  .Auth-form-title {
    text-align: center;
    margin-bottom: 1em;
    font-size: 24px;
    color: rgb(34, 34, 34);
    font-weight: 660;
  }
  
  label {
    font-size: 14px;
    font-weight: 600;
    color: rgb(34, 34, 34);
  }
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }