.chat-left {
    float: left;
    margin: 10px;
    margin-right: 32px;
    padding: 10px;
    background-color: #e1ffc7;
    border-radius: 10px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    clear: both;
}

.chat-right {
    float: right;
    margin: 10px;
    margin-left: 32px;
    padding: 10px;
    background-color: #c7e1ff;
    border-radius: 10px;
    box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.2);
    clear: both;
}